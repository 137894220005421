import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Radio, RadioGroup, TextField, Typography, FormControlLabel } from '@mui/material';
import { FaRegTrashAlt } from "react-icons/fa";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import DraggableFeatured from '../Components/DraggableFeatured';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {  createTheme, ThemeProvider, CircularProgress } from "@mui/material";
import { setColors, setTheme, setProfileImage, setBasicInfo, setContacts, setCustomContacts, setCoverImage, setSections, setID, setIsPersonal, setSelectedLanguage }
 from "../Redux/Slices/FormSlice.js";
import axios from 'axios';

function Posts() {
  const [postContent, setPostContent] = useState("");
  const [mediaType, setMediaType] = useState("image");
  const [mediaFile, setMediaFile] = useState(null);
  const [posts, setPosts] = useState([]);
  // const [profile, setProfile] = useState(localStorage.getItem("profile"));
  const profile = localStorage.getItem("profile")
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAndDispatchProfileData();
  }, []);
  const fetchAndDispatchProfileData = async () => {
    if (profile == null || profile == 'null') return;
    try {
      const { data } = await axios.get(`https://api.tebsocial.com/api/profile/${profile}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const formData = data.data;
      dispatch(setColors({ buttonsBackground: formData.buttonColor, mainBackground: formData.bgColor }));
      dispatch(setTheme({ theme: formData.theme.id }));
      localStorage.setItem("theme", formData.theme.id);
      dispatch(setSelectedLanguage({ SelectedLanguage: localStorage.getItem("lang") }));

      dispatch(setID({ id: formData.id }));
      dispatch(setIsPersonal({ isPersonal: formData.isPersonal }));
      dispatch(setCoverImage({ coverImage: formData.cover }));
      dispatch(setProfileImage({ image: formData.photo }));
      
      dispatch(setBasicInfo({
        basic: { 
          firstName: formData.firstName || "", lastName: formData.lastName || "", jobTitle: formData.jobTitle || '', 
          businessName: formData.businessName, location: formData.location, bio: formData.bio || "", 
          phoneNumber: formData.phoneNum, email: formData.email, phoneNumSecondary: formData.phoneNumSecondary, phoneNumberWA: formData.phoneNumberWA }
      }));
      formData.primary_links.forEach(contact => {
        dispatch(setContacts({ type: contact.name, value: contact.value, idInthback: contact.id, logo: contact.logo }));
      });
      formData?.second_links?.forEach((customContact, index) => {
        dispatch(
          setCustomContacts({
            id: index , 
            link: customContact.link,
            logo: customContact.logo,
            name_link: customContact.name_link,
          })
        );
      });
      const sectionsToAdd = formData.section.map((section) => ({
        id: section.id,
        title: section.title,
        media: section.media,
        cover_for_video: section.cover_for_video,
        contentType: "video",
      }));
      dispatch(setSections(sectionsToAdd));
    } catch (error) {
      console.log(error);
    }
  };

  // Handle content change
  const handleContentChange = (event) => {
    setPostContent(event.target.value);
  };

  // Handle media type change (image or video)
  const handleMediaTypeChange = (event) => {
    setMediaType(event.target.value);
  };

  // Handle media file upload
  const handleMediaUpload = (event) => {
    setMediaFile(event.target.files[0]);
  };

  // Handle publish post
  const handlePublishPost = () => {
    if (postContent || mediaFile) {
      setPosts([...posts, { content: postContent, media: mediaFile, mediaType }]);
      setPostContent("");
      setMediaFile(null);
    }
  };

  // Handle delete post
  const handleDeletePost = (index) => {
    const updatedPosts = posts.filter((_, postIndex) => postIndex !== index);
    setPosts(updatedPosts);
  };

  return (
    <Box padding={4} maxWidth="800px" margin="0 auto">
      {/* Add New Post Section */}
    <DraggableFeatured/>
    </Box>
  );
}

export default Posts;