import React, { createContext, useContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('lang') || 'en');

  useEffect(() => {
    const lang = localStorage.getItem("lang");
  
    if (lang === 'en' || lang === 'ar') {
      localStorage.setItem("lang", lang);
      setLanguage(lang);
    } else if (lang !== 'en' && lang !== 'ar') {
      localStorage.setItem("lang", 'en');
      setLanguage('en');
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedLanguage = localStorage.getItem('lang');
      if (storedLanguage && storedLanguage !== language) {
        setLanguage(storedLanguage);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [language]);

  const changeLanguage = (newLang) => {
    localStorage.setItem('lang', newLang);
    setLanguage(newLang);
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage: changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export { LanguageProvider, useLanguage };