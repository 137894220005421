import ResetEmail from "../Components/ResetEmail";
import Reset from "../Components/ResetPassword";
import SendVeruficationToEmail from "../Components/sendVerificationCodeToEmail";
import ResendOTP from "../Components/resendOTP";
import Dashboard from "../Pages/Dashboard";
import Settings from "../Pages/Settings";
import Main from "../Pages/Home/Main.js";
import PrivateRoutes from "./PrivateRoutes";
import Forgetpassword from "../Components/Forgetpassword";
import SendOtpForForgetPasword from "../Components/sendVerificationCodeToEmail";
import Posts from "../Pages/Posts.js";

const AuthenticationRoutes = {
  element: <PrivateRoutes />,

  children: [
    {
      element: <Main />,
      path: "main",
    },
    { element: <Settings />, path: "/settings" },
    { element: <Posts />, path: "/posts" },
    {
      element: <Dashboard />,
      path: "/Dashboard",
    },
  ],
};
export default AuthenticationRoutes;
