import React, { useState, useEffect } from 'react';
import {
  Box, Button, IconButton, TextField, Typography, Card, CardContent, Dialog,
  CardMedia, CircularProgress, Snackbar, Alert, DialogActions, DialogContent,
  DialogContentText, DialogTitle, createTheme, ThemeProvider,
  Stack
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import WarningIcon from '@mui/icons-material/Warning';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { FaRegTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

const lang = localStorage.getItem('lang');
const fontFamily = lang === 'ar' ? 'system-ui' : 'Roboto';

const theme = createTheme({
  palette: {
    mode: "light",
    primary: { main: "#17488a" },
    secondary: { main: "#705ea8" },
    lighter: { main: "#497cbe" },
  },
  typography: {
    fontFamily,
    title: { fontSize: "0.9rem", fontWeight: 'bold' },
    linkSelected: { backgroundColor: "#fff", fontSize: "0.9rem", display: "block" },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: {
            textAlign: lang === "ar" ? "right" : "left",
            right: lang === "ar" ? 0 : "auto",
            left: lang === "ar" ? "auto" : 0,
            direction: lang === "ar" ? "rtl" : "ltr",
            fontSize: "1rem",
            color: "#000",
          },
        },
        InputProps: {
          inputProps: {
            style: {
              textAlign: lang === "ar" ? "right" : "left",
              direction: lang === "ar" ? "rtl" : "ltr",
              color: "#000",
            },
          },
        },
      },
    },
  },
});

const dialogVariants = {
  initial: { scale: 0.5, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.5, opacity: 0 }
};

const DraggableFeatured = () => {
  const [sections, setSections] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [coverImageDialogOpen, setCoverImageDialogOpen] = useState(false);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const profile = localStorage.getItem('profile');
  const token = localStorage.getItem('token');
  const { t } = useTranslation();
  const [saveLoading, setSaveLoading] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleOpenDeleteDialog = (index) => {
    setDeleteIndex(index);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteIndex(null);
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`https://api.tebsocial.com/api/profile/${localStorage.getItem('profile')}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(response => {
      setSections(response.data.data.section.map(section => ({
        ...section,
        editing: false,
        contentType: section.name_of_file,
        cover_for_video: section.cover_for_video || ''
      })));
      setLoading(false);
    }).catch(error => {
      console.error(error);
      setSnackbar({ open: true, message: t('Failed to load data...'), severity: 'error' });
      setLoading(false);
    });
  }, []);
  const handleSave = async (index) => {
    setSaveLoading(true);
    const formData = new FormData();
    const section = sections[index];
  
    sections.forEach((section, index) => {
      formData.append(`sections[${index}][title]`, section.title);
      formData.append(`sections[${index}][name_of_file]`, section.contentType);
  
      // Append media file if it exists
      if (section.media instanceof File || typeof section.media === 'string') {
        formData.append(`sections[${index}][media]`, section.media);
      }
      if (section.cover_for_video instanceof File || typeof section.cover_for_video === 'string') {
        formData.append(`sections[${index}][cover_for_video]`, section.cover_for_video);
      }
      // Append cover_for_video file if it exists
      // if (section.cover_for_video instanceof File) {
      //   formData.append(`sections[${index}][cover_for_video]`, section.cover_for_video);
      // } else if (typeof section.cover_for_video === 'string' && section.cover_for_video) {
      //   // If it's a string URL, append it as a file (if needed)
      //   fetch(section.cover_for_video)
      //     .then((res) => res.blob())
      //     .then((blob) => {
      //       const file = new File([blob], 'cover_image.jpg', { type: blob.type });
      //       formData.append(`sections[${index}][cover_for_video]`, file);
      //     });
      // }
    });
  
    axios
      .post(
        `https://api.tebsocial.com/api/profile/${localStorage.getItem('profile')}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((response) => {
        const updatedSections = sections.map((sec, idx) =>
          idx === index ? { ...sec, ...response.data.data.section[idx], editing: false } : sec
        );
        setSections(updatedSections);
        setSnackbar({ open: true, message: t('Update successfully!'), severity: 'success' });
      })
      .catch((error) => {
        let errorMessage;
        if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = t('There seems to be a problem with the data you submitted.');
              break;
            case 401:
              errorMessage = t('You are not authorized. Please log in and try again.');
              break;
            case 422:
              errorMessage = t('Your info is incorrect. Please check and try again.');
              break;
            case 404:
              errorMessage = t('The requested resource was not found.');
              break;
            case 500:
              errorMessage = t('Our server is having issues, please try again later.');
              break;
            default:
              errorMessage = t('Server responded with an error: ') + error.response.status;
              break;
          }
        } else {
          errorMessage = t('An error occurred, please try again.');
        }
        setSnackbar({ open: true, message: errorMessage, severity: 'error' });
      })
      .finally(() => {
        setSaveLoading(false);
        setIsAddingNew(false);
      });
  };  
  const handleAddNewPost = () => {
    setIsAddingNew(true);
    setSections([{ id: null, title: '', media: null,cover_for_video: '', editing: true }, ...sections]);
  };

  const handleCancelNewPost = () => {
    setIsAddingNew(false);
    setSections(prevSections => {
      return prevSections.filter(section => section.id !== null);
    });
  };

  const handleSaveNewPost = async (index) => {
    await handleSave(index);
  };

  const handleCancel = (index) => {
    if (sections[index].id) {
      setSections(prevSections =>
        prevSections.map((section, idx) =>
          idx === index ? { ...section, editing: false } : section
        )
      );
    } else {
      setIsAddingNew(false);
      setSections(prevSections => {
        return prevSections.filter(section => section.id !== null);
      });
    }
  };

  const handleDelete = async () => {
    if (deleteIndex !== null) {
      const newSections = sections.filter((_, index) => index !== deleteIndex);
      setSections(newSections);
      setDeleteDialogOpen(false);
  
      const formData = new FormData();
      if (newSections.length === 0) {
        formData.append(`sections[${0}][title]`, null);
        formData.append(`sections[${0}][name_of_file]`, null);
      } else {
        newSections.forEach((section, index) => {
          formData.append(`sections[${index}][title]`, section.title);
          formData.append(`sections[${index}][name_of_file]`, section.contentType);
          if (section.media instanceof File || typeof section.media === 'string') {
            formData.append(`sections[${index}][media]`, section.media);
          }
          if (section.cover_for_video && Object.keys(section.cover_for_video).length > 0) { // Check if it's not an empty object
            formData.append(`sections[${index}][cover_for_video]`, section.cover_for_video);
          }
        });
      }
  
      axios.post(`https://api.tebsocial.com/api/profile/${localStorage.getItem('profile')}`, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }).then(response => {
        setSnackbar({ open: true, message: t('deleted successfully!'), severity: 'success' });
      }).catch(error => {
        let errorMessage;
        if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = t("There seems to be a problem with the data you submitted.");
              break;
            case 401:
              errorMessage = t("You are not authorized. Please log in and try again.");
              break;
            case 422:
              errorMessage = t("Your info is incorrect. Please check and try again.");
              break;
            case 404:
              errorMessage = t("The requested resource was not found.");
              break;
            case 500:
              errorMessage = t("deleted successfully!.");
              break;
            default:
              errorMessage = t("Server responded with an error: ") + error.response.status;
              break;
          }
        } else if (error.response.status === 500) {
          setSnackbar({ open: true, message: t('deleted successfully!'), severity: 'success' });
        } else {
          errorMessage = t("An error occurred, please try again.");
        }
        setSnackbar({ open: true, message: errorMessage, severity: 'error' });
      });
    }
  };
  const handleEdit = (index) => {
    setSections(sections.map((s, idx) => (idx === index ? { ...s, editing: true } : s)));
  };

  const handleTitleChange = (e, index) => {
    const newTitle = e.target.value;
    setSections(sections.map((section, idx) => (idx === index ? { ...section, title: newTitle } : section)));
  };

  const handleMediaChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const contentType = file.type.startsWith('image/') ? 'image' : 'video';
      setSections(sections.map((section, idx) => (idx === index ? { ...section, media: file, contentType } : section)));
      setSnackbar({ open: true, message: `${contentType.toUpperCase()} selected`, severity: 'info' });
    }
  };
  const handleCoverImageChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const contentType = file.type.startsWith('image/') ? 'image' : 'video';
      setSections(sections.map((section, idx) => (idx === index ? { ...section, cover_for_video: file, contentType } : section)));
      console.log(sections)
      setSnackbar({ open: true, message: `${contentType.toUpperCase()} selected`, severity: 'info' });
    }
  };

  // const handleCoverImageChange = (event, index) => {

  //   const file = event.target.files[0];
  //   if (file) {
  //     setSections((prevSections) =>
  //       prevSections.map((section, idx) =>
  //         idx === index ? { ...section, cover_for_video: file } : section
  //       )
  //     );
  //     setSnackbar({ open: true, message: 'Cover image selected', severity: 'info' });
  //   }
  // };
  const handleRemoveCoverImage = (index) => {
    setSections((prevSections) =>
      prevSections.map((section, idx) =>
        idx === index ? { ...section, cover_for_video: '' } : section
      )
    );
    console.log(sections)
  };
  const handleShowCoverImage = (index) => {
    const coverImage = sections[index]?.cover_for_video;
    
    if (coverImage) {
      // Use URL.createObjectURL for File instances
      if (coverImage instanceof File) {
        const previewURL = URL.createObjectURL(coverImage);
        setCoverImagePreview(previewURL); // Store temporary URL
      } else if (typeof coverImage === 'string') {
        setCoverImagePreview(coverImage); // Use URL string directly
      }
      setCoverImageDialogOpen(true);
    } else {
      setSnackbar({ open: true, message: 'No cover image available', severity: 'info' });
    }
  };
  const handleCloseCoverImageDialog = () => {
    setCoverImageDialogOpen(false);
    setCoverImagePreview(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: '2rem' }}>
      <Snackbar 
      open={snackbar.open} 
      autoHideDuration={6000} 
      onClose={() => setSnackbar({ ...snackbar, open: false })} 
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert 
        onClose={() => setSnackbar({ ...snackbar, open: false })} 
        severity={snackbar.severity} 
        action={
          <Button color="inherit" size="small" onClick={() => setSnackbar({ ...snackbar, open: false })}>
            Dismiss
          </Button>
        }
        sx={{ width: '100%' }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
        <Dialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("Confirm_Deletion")}</DialogTitle>
          <DialogContent>
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ rotate: [0, 10, -10, 10, -10, 0], scale: 1 }}
              transition={{ duration: 0.8 }}
            >
              <WarningIcon color="warning" style={{ fontSize: 60, margin: '0 auto', display: 'block' }} />
              <DialogContentText id="alert-dialog-description">
                {t("Are_you_sure_deleteyou")}
              </DialogContentText>
            </motion.div>
          </DialogContent>
          <DialogActions>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            <Button onClick={handleCloseDeleteDialog}>{t("cancel")}</Button>
            <Button onClick={handleDelete} color="error" autoFocus>{t("delete")}</Button>
          </Stack>
        </DialogActions>
        </Dialog>
        <Dialog open={coverImageDialogOpen} onClose={handleCloseCoverImageDialog}>
          <DialogTitle>{t('Cover_Image_Preview')}</DialogTitle>
          <DialogContent>
            <img src={coverImagePreview} alt="Cover Preview" style={{ width: '100%' }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCoverImageDialog}>{t('close')}</Button>
          </DialogActions>
        </Dialog>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <AnimatePresence>
            {!isAddingNew && (
              <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddNewPost} sx={{ mb: 2 }}>
                {t('New_Post')}
              </Button>
            )}
            {sections.map((section, index) => (
              <motion.div
                key={section.id}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ duration: 0.5 }}
              >
                <Card sx={{ maxWidth: 345, mb: 2, borderRadius: "20px", boxShadow: 3 }}>
                  {section.editing ? (
                    <>
                      <Typography variant="h6" padding={2}>
                        {section.id ? t('Editing_Post') : t('New_Post')}
                      </Typography>
                      <TextField
                        fullWidth
                        variant="standard"
                        label={t('Title')}
                        value={section.title}
                        onChange={(e) => handleTitleChange(e, index)}
                        sx={{ marginX: "10px" }}
                      />
                      <Button component="label" sx={{ m: 1 }}>
                        {section.id ? t('Change_Media') : t('choose_Media')}
                        <input type="file" hidden accept="image/*,video/*" onChange={(e) => handleMediaChange(e, index)} />
                      </Button>
                      {section.contentType === 'video' || typeof section.media === 'string' && /\.(mp4|mov|avi|mkv|webm|flv|wmv|mpeg)$/i.test(section.media) ? 


                     <>
                      {
                        section.cover_for_video && Object.keys(section.cover_for_video).length > 0 && typeof section.cover_for_video === 'string' ||section.cover_for_video instanceof File ? (
                          <>
                            <Button onClick={() => handleShowCoverImage(index)} sx={{ m: 1 }}>
                              {t('Show_Cover_Image')}
                            </Button>
                            <Button onClick={() => handleRemoveCoverImage(index)} sx={{ m: 1 }}>
                              {t('Remove_Cover_Image')}
                            </Button>
                          </>
                        ) : (
                          <Button component="label" sx={{ m: 1 }}>
                            {t('Add_Cover_Image')}
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={(e) => handleCoverImageChange(e, index)}
                            />
                          </Button>
                        )
                      }  
                        </> 
                        :""
                      }                  
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                        <Button startIcon={<SaveIcon />} onClick={() => handleSave(index)} variant="contained" color="primary" disabled={!section.title || !section.media || saveLoading}>
                          {saveLoading ? <CircularProgress size={24} /> : t('save')}
                        </Button>
                        <Button style={{ marginLeft: "10px", marginRight: "10px" }} startIcon={<CancelIcon />} onClick={() => handleCancel(index)} variant="outlined" color="error">
                          {t('cancel')}
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <CardMedia
                        component={section.contentType === 'video'|| typeof section.media === 'string' && /\.(mp4|mov|avi|mkv|webm|flv|wmv|mpeg)$/i.test(section.media)  ? 'video' : 'img'}
                        alt={section.title}
                        height="140"
                        image={section.media instanceof File ? URL.createObjectURL(section.media) : section.media}
                        title={section.title}
                        sx={{ height: 140 }}
                        controls
                      />
                      <CardContent>
                        <Typography variant="h6">{section.title || t('Untitled')}</Typography>
                      </CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                        <IconButton onClick={() => handleEdit(index)} color="primary">
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteDialog(index)} color="error">
                          <FaRegTrashAlt />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </Card>
              </motion.div>
            ))}
          </AnimatePresence>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default DraggableFeatured; 