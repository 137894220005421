import React , {useEffect,useState , useRef}from "react";
import { useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { RWebShare } from "react-web-share";
import { QRCode } from 'react-qrcode-logo';
import { pdfjs } from "react-pdf";
import tinycolor from "tinycolor2";
import { LiaPhoneVolumeSolid } from "react-icons/lia";

import { TbCalendarPlus } from "react-icons/tb";
import { RiWhatsappLine } from "react-icons/ri";

import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { MdOutlineAddLocation } from "react-icons/md";

import { FaPlus } from "react-icons/fa";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { IoShareSocial } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";
import { PiUploadSimpleBold } from "react-icons/pi";
import { Box, SpeedDial, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress"; 
import axios from "axios";
import { ImageList, ImageListItem, ImageListItemBar, IconButton } from '@mui/material';
import { IoMdImages, IoMdVideocam } from 'react-icons/io';
import { MdOutlineShare, MdOutlineArticle } from 'react-icons/md'; // New modern icons
import { motion } from "framer-motion"; // Ensure this is installed
import { FaShareFromSquare } from "react-icons/fa6";
import PostGallery from '../PostsPage.js'
import { useSelector } from 'react-redux';

const Template16 = () => {
  const form = useSelector(state => state.form);
  console.log(form,'formform')
  const mainBackgroundColor = form.colors.mainBackground;
  const buttonsBackground = form.colors.buttonsBackground;
  const textColor = tinycolor(mainBackgroundColor).isDark() ? "#ffffff" : "#000000";
  const textButtonColor = tinycolor(buttonsBackground).isDark() ? "#ffffff" : "#000000";

  const [isExpanded, setIsExpanded] = useState(false);
  const handleAddToContactsClick = () => {
    setIsExpanded(true);

    // Auto-collapse after 2 seconds
    setTimeout(() => {
      setIsExpanded(false);
    }, 2000);
  };

  // Animation variants
 

  // Spark animation for visual effects
  const sparkVariants = {
    initial: { opacity: 0, scale: 0.5 },
    animate: {
      opacity: [0, 1, 0],
      scale: [0.5, 1.5, 0.5],
      transition: {
        duration: 0.8,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "loop",
      },
     

    },
  };
  useEffect(() => {
    setTimeout(() => {
      setIsExpanded(true);

      // Auto-collapse after a delay
      setTimeout(() => {
        setIsExpanded(false);
      }, 2000);
    }, 500); // Add slight delay before expanding on page load
  }, []);


 
 const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const [urlToShare, seturlToShare] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [opennnn, setOpennnn] = useState(false);
  const handleCloseeee = () => setOpennnn(false);
  const [animate, setAnimate] = useState(false);
  const lang = form?.SelectedLanguage || 'en'; // Default to English if undefined
  const [value, setValue] = useState("socials");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [position, setPosition] = useState({
    left: 0,
    width: 0,
    opacity: 1,
  });

  // Refs for individual buttons
  const socialsRef = useRef(null);
  const postsRef = useRef(null);
  const [socialsElement, setSocialsElement] = useState(null);
  const [postsElement, setPostsElement] = useState(null);
  const isValidValue = (value) => {
    return (
      value !== null &&
      value !== "null" &&
      value !== undefined &&
      value !== "undefined" &&
      value !== ""
    );
  };
  
  const bgColor = form.colors.mainBackground || "#f0f0f0"; // Dynamic background color
  const buttonColor = form.colors.buttonsBackground || "#ff5722"; // Dynamic cursor color

  const handleChange = (newValue, element) => {
    setValue(newValue);

    const { width, left } = element.getBoundingClientRect();
    const navLeft = element.parentElement.getBoundingClientRect().left;
// first button is 39.6594px and second is 235.6594px
if (newValue ==  "socials" ){
  setPosition({
    left: "23.6594px" ,
    width :"182.866px",
    opacity: 1,
  });
}
else if (newValue ==  "posts") {

    setPosition({
      left: "226.659px" ,
      width :width+80,
      opacity: 1,
    });
  }

  };
  useEffect(() => {
    // Set initial position on "Socials" using callback ref value
    if (socialsElement) {
      const { width, left } = socialsElement.getBoundingClientRect();
      const navLeft = socialsElement.parentElement.getBoundingClientRect().left;

      if (value ==  "socials" ){
        setPosition({
          left: "23.6594px" ,
          width :"182.866px",
          opacity: 1,
        });
      }
      else if (value ==  "posts") {
      
          setPosition({
            left: "226.659px" ,
            width :width+80,
            opacity: 1,
          });
        }
          }
  }, [socialsElement]); // Run this when the socialsElement is set


  console.log(form?.contactsCustom,"asdaa")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const translations = {
    en: {
      phone: "Phone",
      email: "Email",
      whatsapp: "WhatsApp", 
      bookNow: "Book Now",
      addToContacts: "Add To Contacts",
      copyLink: "Copy Link",
      shareLink: "Share Link",
      myQrCode: "My QR Code",
      socials: "Socials",
      posts: "Posts",
      contactUs: "Contact Us Via:",
      emailContact: "EMAIL"
    },
    ar: {
      phone: "هاتف",
      email: "البريد الإلكتروني",
      whatsapp: "واتساب",
      bookNow: "احجز الآن",
      addToContacts: "جهات الاتصال",
      copyLink: "نسخ الرابط",
      shareLink: "مشاركة الرابط",
      myQrCode: "رمز الاستجابة السريعة الخاص بي",
      socials: "التواصل الاجتماعي",
      posts: "المنشورات",
      contactUs: "اتصل بنا عبر:",
      emailContact: "البريد الإلكتروني"
    }
  };
 
  let retryCount = 0;
  const maxRetryCount = 3; // Maximum number of retries
  const retryDelay = 2000; 
  const getCurrentUrl = () => {
    seturlToShare(window.location.href);
  };
  const retryFetch = () => {
    if (retryCount < maxRetryCount) {
      setTimeout(() => {
        fetchData();
        retryCount++;
      }, retryDelay);
    } else {
      console.error("Maximum retry attempts reached.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUrl();
  }, []);
  const opene = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const speedDialRef = useRef();
  const location = useLocation();

  const [oppen, setOppen] = React.useState(false);
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  document.documentElement.style.setProperty(
    "--bg-color",
    form.colors.mainBackground
  );
  document.documentElement.style.setProperty(
    "--button-bg-color",
    form.colors.buttonsBackground
  );
  document.documentElement.style.setProperty(
    "--border-color",
    form.colors.buttonsBackground
  );

  

    useEffect(() => {
    const handleDocumentClick = (e) => {
      if (speedDialRef.current && !speedDialRef.current.contains(e.target)) {
        setOppen(false); // Close SpeedDial
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  let apicounter = 0;
// const isPersonal= form?.isPersonal =="true" ? true : false
  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");
const [isPersonal,setisPersonal]=useState(false)
  const [inactiveColor, setinactiveColor] = useState("#ffffff");

    useEffect(() => {

     if (form?.isPersonal =='true'){
    setisPersonal(true)
console.log(isPersonal,'boolian isPersonal')

  }
    
    else {
        setisPersonal(false)
console.log(form,'form?.isPersonal')
console.log(isPersonal,'boolian isPersonal')

    }

  }, [form?.isPersonal,isPersonal]);

  useEffect(() => {
    const mainBackgroundColor = form.colors.mainBackground;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();

      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [form.colors.mainBackground]);

  useEffect(() => {
    const buttonsBackground = form.colors.buttonsBackground;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();

      // Adjust text color based on brightness
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
      setinactiveColor(tinycolor(textcolor).lighten(30).toString())
    }
  }, [form.colors.buttonsBackground]);


  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const params = new URLSearchParams(window.location.search);

  const profileValue = params.get("user");

  console.log("user:", profileValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

   const handleCopyLink = () => {
    navigator.clipboard.writeText(urlToShare);
    setIsVisible(true); // Show "link copied" message
    setTimeout(() => {
      setIsVisible(false);
    }, 1000);
  };

  const handleOpenQRModal = () => {
    setOpen(true);
  };
  const handleCloseQRModal = () => setOpen(false);

  const handleDocumentClick = (e) => {
    if (speedDialRef.current && !speedDialRef.current.contains(e.target)) {
      // Click occurred outside the SpeedDial, hide it
      setOppen(false);
    }
  };
 const toDataURL = (url) =>
  axios.get(url, {
    responseType: "blob",
    headers: {
      'Accept': '*/*' // Assuming the server responds with any type of content
    }
  })
  .then((response) => {
    console.log(`Blob Size: ${response.data.size} bytes`);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('Data URL:', reader.result); // Log the data URL
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        console.error("FileReader Error:", error);
        reject(error);
      };
      reader.readAsDataURL(response.data);
    });
  })
  .catch((error) => {
    console.error("Error fetching and converting data:", error);
    return null; // Return null on error to handle gracefully
  });

  const saveToContacts = async () => {
    const contact = {
      name: form?.basic?.firstName + " " + form?.basic?.lastName,
      mobile: form?.basic.phoneNumber || "",
      email: form?.basic.email || "",
      address: form?.basic?.location || "",
      organization: form?.basic.businessName || "",
      TebSocialWebsite: urlToShare,
      photo: form?.profileImage || "",
      primary_links: form?.contacts || [],
    };

    let imageDataUrl = "";
    if (form?.profileImage !== "") {
  imageDataUrl = await toDataURL(form?.profileImage);
        console.log("imageDataUrl,imageDataUr", imageDataUrl);
      if (!imageDataUrl) {
        console.error("Failed to convert image to Data URL");
        return;
      }
    }

    let vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:${contact.name};;;\n` +
      `FN:${contact.name}\n` +
      `TEL;CELL:${contact.mobile}\n` +
      `EMAIL;HOME:${contact.email}\n` +
      `ADR;HOME:${contact.address}\n` +
      `ORG;WORK:${contact.organization}\n` +
      `item1.URL:${contact.TebSocialWebsite}\n` +
      `item1.X-ABLabel:Teb Social Profile\n` +
      `PHOTO;ENCODING=b;TYPE=JPEG:${imageDataUrl.split(",")[1]}\n`;

// Handling primary_links
contact?.primary_links?.forEach((link, index) => {
  const itemNumber = index + 3; // Starting from 3 as 1 is used for TebSocialWebsite
  vcard +=
    `item${itemNumber}.URL:${link.value}\n` +
    `item${itemNumber}.X-ABLabel:${link.name}\n`;
});

  const itemNumber = (contact?.primary_links?.length || 0) + 3;
  vcard += 
    `item${itemNumber}.URL:${form?.contactsCustom[0]?.link}\n` +
    `item${itemNumber}.X-ABLabel:Medical specialty\n`;




    vcard += `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${contact.name}.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);




const [openPost, setOpenPost] = useState(false);
const [selectedPost, setSelectedPost] = useState(null);
const handleOpenPost = (post) => {
  setSelectedPost(post);
  setOpenPost(true);
};

  const handleSpeedDialClick = () => {
    setOppen(!oppen);
    setTooltipOpen(!tooltipOpen); // Toggle tooltip visibility based on SpeedDial state
  };
  const speedDialAnimation = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: 50, transition: { duration: 0.5 } },
  };

  const buttonAnimation = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.7, delay: 0.3 } },
  };
 const buttonVariants = {
    initial: { 
      width: "56px",
      height: "56px", // Ensure it's always a circle
      borderRadius: "50%", // Make it fully circular
      padding: "5px",
      borderColor:textcolor
    },
    expanded: {
      width: "200px",
      height: "56px",
      borderRadius: "56px", // Keep circular ends
      padding: "5px",
      transition: {
        type: "spring", 
        stiffness: 180,
        damping: 18,
        duration: 0.6,
      },
      borderColor:textcolor

    },
    collapsed: {
      width: "56px",
      height: "56px",
      borderRadius: "50%", // Fully circular
      padding: "5px",
      transition: {
        type: "spring",
        stiffness: 180,
        damping: 18,
        duration: 0.6,
      },
      borderColor:textcolor

    },
  };
  

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <CircularProgress /> {/* Loading spinner */}
      </div>
    );
  }


  function hexToRgba(hex, opacity = 1) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex?.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex?.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
  }
  const startColor = hexToRgba(form.colors.mainBackground, 0); // Fully transparent
  const endColor = hexToRgba(form.colors.mainBackground, 1); // Fully opaque
  return (
    <div className={`w-full h-full text-center relative`} style={{ backgroundColor: form.colors.mainBackground }}>
    
    <div className=' absolute top-0 bottom-0 left-0 right-0 -z-20 ' style={{backgroundColor: form.colors.mainBackground}}></div>
      <div
        style={{
          position: "relative",
          height: "60vh",
          width: "100%",
          overflow: "hidden",
        }}
      >
      <div
      className="fixed w-full h-full -z-10 "
      style={{ backgroundColor: form.colors.mainBackground }}
    ></div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${form?.profileImage})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            zIndex: 0,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
            background: `linear-gradient(to bottom, ${startColor}, ${endColor})`,
            zIndex: 1,
          }}
        >
          <div className="flex flex-col justify-end items-center  h-full  w-full">
                <motion.div
                   className="pt-12 md:pt-16 w-20 w-full flex justify-center"
               initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
               transition={{ duration: 0.8 }}
              >
        {isPersonal === true ? (
          <motion.h2
            className="md:pb-3 text-xl font-bold white"
            style={{ color: textcolor, fontWeight: "bold", fontSize: "1.8rem" ,whiteSpace: "nowrap"}}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.1, duration: 0.7 }}
          >
          {isValidValue(form?.basic?.firstName) && isValidValue(form?.basic?.lastName)
              ? `${form?.basic?.firstName} ${form?.basic?.lastName}`
              : ""}
          </motion.h2>
        ) : (
          <motion.h2
            className="pb-2 md:pb-3 text-xl font-bold"
            style={{ color: textcolor, fontWeight: "bold", fontSize: "1.8rem",whiteSpace: "nowrap" }}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.1, duration: 0.7 }}
          >
            {isValidValue(form?.basic.businessName) ? form?.basic.businessName : ""}
          </motion.h2>
        )}
      </motion.div>

     
          </div>
        </div>
      </div>




      
      <div className="mb-4">
  

  <div className="w-full h-full" style={{ backgroundColor: form.colors.mainBackground }}>
        <div style={{ backgroundColor: form.colors.mainBackground , marginLeft:"10px",
                    marginRight:"10px"}}>
            <div  
                className="fixed w-full h-full -z-10 "
                style={{ backgroundColor: form.colors.mainBackground}}
            ></div>
       
        <div
      className="relative text-center border-2 mt-4 p-4"
      style={{
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "auto",
        
        transition: "0.5s filter linear",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "30px",
      }}
    >
    <div className="mt-6"> {/* Corrected className */}
      {/* Second Links */}
      {form?.contactsCustom?.length === 1 || (isPersonal === true && form?.contactsCustom?.length >= 1) ? (
        <motion.h2
          className="text-xs font-semibold"
          style={{
            color: textcolor,
            textAlign: lang === 'ar' ? 'center' : 'center', // Conditional text alignment
            padding: '5px', // Add padding
          }}
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          {form?.contactsCustom[0]?.link}
        </motion.h2>
      ) : (
        isPersonal === false && form?.contactsCustom?.map((primaryItem, primaryIndex) => (
          <motion.h2
            key={primaryIndex}
            className="text-xs font-semibold"
            style={{
              color: textcolor,
              textAlign: lang === 'ar' ? 'center' : 'center', // Conditional text alignment
              padding: '5px', // Add padding
            }}
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5 + primaryIndex * 0.2, duration: 0.8 }}
          >
            {isValidValue(primaryItem?.link) ? primaryItem?.link : ""}
          </motion.h2>
        ))
      )}
    </div>
    { isValidValue(form?.basic?.phoneNumSecondary) && (
      <motion.h2
      className="text-xs font-semibold"
        style={{
          color: textcolor,
          textAlign: lang === 'ar' ? 'center' : 'center', // Conditional text alignment
          padding: '5px', // Add padding
        }}
        initial={{ opacity: 0, x: -30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        {form?.basic?.phoneNumSecondary}
      </motion.h2>
    )}

    {/* Conditional Text for Business Name */}
      {isPersonal == true && isValidValue(form?.basic.businessName) && (
        <motion.h2
          className="pt-2 md:pt-2 text-2xl font-bold"
          style={{ color: textcolor, fontWeight: "bold", fontSize: "1.5rem" }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.8 }}
        >
          {form?.basic.businessName}
        </motion.h2>
      )}

      {/* Location */}
      {isValidValue(form?.basic?.location) && (
<motion.h2
  className="text-sm Robote pb-1 mt-2 flex justify-center"
  style={{
    color: textcolor,
    textAlign: lang === 'ar' ? 'center' : 'center', // Conditional text alignment
    padding: '5px', // Add padding
  }}
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.4, duration: 0.8 }}
>
  <MdOutlineAddLocation  color={textcolor} size={20}/>

<span className='px-2'>  {form?.basic?.location} </span>
</motion.h2>
      )}


      {/* Bio */}
      {isValidValue(form?.basic?.bio) && (
  <motion.h2
    className="text-md font-semibold my-3"
    style={{
      color: textcolor,
      textAlign: lang === 'ar' ? 'center' : 'center', // Conditional text alignment
      padding: '5px',
       whiteSpace: "pre-line"
    }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.7, duration: 0.8 }}
  >
    {form?.basic?.bio}
  </motion.h2>
      )}
    </div>
        </div>
        <div className="rounded-lg flex flex-row justify-between items-center gap-2 px-[6%] pb-5 pt-10">
  <button className="w-14 h-14 flex flex-col justify-center items-center text-[textcolor]"
    style={{
      color: `${textcolor}`,
    }}
    onClick={() => {
      const cleanedPhoneNum = form?.basic.phoneNumber.replace(/\s+/g, ""); // Remove all spaces
      window.open(`tel:${cleanedPhoneNum}`);
    }}>
    <LiaPhoneVolumeSolid size={36} />
    <p className='text-sm whitespace-nowrap'>{translations[lang].phone}</p>
  </button>

  <button className="w-16 h-16 flex flex-col justify-center items-center text-[textcolor]"
    style={{
      color: `${textcolor}`,
    
    }}
    onClick={() => {
      window.open(`mailto:${form?.basic.email}`);
    }}>
    <MdOutlineMarkEmailUnread size={36} />
    <p className='text-sm whitespace-nowrap'>{translations[lang].email}</p>
  </button>
{
  form?.basic.phoneNumberWA  ? 
  <button className="w-16 h-16 flex flex-col justify-center items-center text-[textcolor]"
    style={{
      color: `${textcolor}`,
    }}
    onClick={() => {
      const cleanedPhoneNum = form?.basic.phoneNumberWA.replace(/\s+/g, ""); // Remove all spaces
      window.open(`https://wa.me/${cleanedPhoneNum}`);
    }}>
    <RiWhatsappLine size={36}/>
    <p className='text-sm whitespace-nowrap'>{translations[lang].whatsapp}</p>
  </button>
  :""
}
{form?.basic?.jobTitle ?
  <button className="w-16 h-16 flex flex-col justify-center items-center text-[textcolor]"
    style={{
      color: `${textcolor}`,
    }}
    onClick={() => {
      window.open(`${form?.basic.jobTitle}`);
    }}>
    <TbCalendarPlus size={36}/>
    <p className='text-sm whitespace-nowrap'>{translations[lang].bookNow}</p>
  </button>
  :''}
</div>
<div className=' flex justify-center'>
  <div
   className="relative flex w-[100vw]  p-1 mb-10"
   style={{
backgroundColor: bgColor, // Use  background color
borderTop: `2px solid ${buttonColor}`, // Border for the top
borderBottom: `2px solid ${buttonColor}`, // Border for the bottom

   }}
 >
   <ul className="relative flex justify-around w-full z-10">
     {/* Socials tab */}
     <li
       ref={setSocialsElement} // Set ref using callback ref pattern
       className="relative flex items-center cursor-pointer px-4 py-2 text-center transition-all duration-300"
       onClick={() => handleChange("socials", socialsElement)}
       style={{
         color: value === "socials" ? textbottoncolor : textcolor,
         fontWeight: value === "socials" ? "bold" : "normal",
       }}
     >
       <MdOutlineShare size="24px" />
       <span className="ml-2">
       {lang === 'ar' ? translations.ar.socials : translations.en.socials}
     </span>
     </li>

     {/* Posts tab */}
     <li
       ref={setPostsElement} // Set ref using callback ref pattern
       className="relative flex items-center cursor-pointer px-4 py-2 text-center transition-all duration-300"
       onClick={() => handleChange("posts", postsElement)}
       style={{
         color: value === "posts" ? textbottoncolor : textcolor,
         fontWeight: value === "posts" ? "bold" : "normal",
       }}
     >
       <MdOutlineArticle size="24px" />
     <span className="ml-2">
       {lang === 'ar' ? translations.ar.posts : translations.en.posts}
     </span>
             </li>
   </ul>

   {/* Animated cursor with background effect */}
   <motion.div
     animate={position}
     className="absolute top-0 bottom-0 z-0 rounded-[20px]"
     style={{
       height: "77%", // Full height of the bar
       marginTop:"5px",
       backgroundColor: buttonColor, // Use  button color for the cursor background
//             borderTopLeftRadius: "10px", // Radius for the bottom-left corner
// borderTopRightRadius: "10px", // Radius for the bottom-right corner

       }}
  
   />
 </div>
</div>
{
<div
  className="w-full flex justify-center"
  style={{
    backgroundColor: value === 'socials' ? form.colors.mainBackground : buttonColor
  }}
>
  <div className={`w-[100%] ${value === 'socials' ? 'mb-[200px]' : ''}`}>
    {value == 'socials' ? (
     <div className="grid grid-cols-3 justify-center items-center  mt-5 gap-y-12 px-[10px] place-items-center">
      {[...form?.contacts] // Create a shallow copy to avoid modifying the original array
        ?.sort((a, b) => a.idInthback - b.idInthback) // Sort based on idInthback
        .map((primaryItem, primaryIndex) => (
                      <div
              className="animate-shakeX"
              key={primaryIndex}
              style={{
                width: "50px", // Fixed width for the container
                height: "50px", // Fixed height for the container
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              {form?.contacts?.[primaryIndex]?.name !== "Telegram" ? (
                <a
                  href={primaryItem?.value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={primaryItem?.logo}
                    alt="logo"
                    style={{
                      width: "100%", 
                      height: "100%",
                      objectFit: "fill",
                    }}
                  />
                </a>
              ) : (
                <a
                  href={primaryItem?.value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    onClick={() => {
                      const cleanedPhoneNum = form?.basic.phoneNumber.replace(
                        /\s+/g,
                        ""
                      ); 
                      const telegramUsername =
                      form?.contacts?.[primaryIndex]?.value; // Replace with the actual username or ID
                      window.open(`https://t.me/${telegramUsername}`);
                    }}
                    src={primaryItem?.logo}
                    on
                    alt="logo"
                    style={{
                      width: "100%", // Stretch the logo to fill the container width
                      height: "100%", // Stretch the logo to fill the container height
                      objectFit: "fill", // This will stretch the image to fill the container
                    }}
                  />
                </a>
              )}
            </div>
          ))}

        
        </div>
    ) :
     
    (


    <div className=' pb-32 '  style={{ backgroundColor: form.colors.mainBackground }}>
      <div classname='w-full h-full  ' style={{ backgroundColor: form.colors.mainBackground }}
      >
           <PostGallery />
           </div>  </div>

    )}

  </div>
</div>
}



 


     
      <div onClick={()=>{saveToContacts()}}>
      <motion.div
        onClick={handleAddToContactsClick}
        initial="initial"
        animate={isExpanded ? "expanded" : "collapsed"}
        variants={buttonVariants}
        className="fixed right-4 bottom-10 z-50 flex items-center justify-center rounded-full "
        style={{
          backgroundColor: buttonColor,
          borderColor: textbottoncolor,
          border: "2px solid",
        }}
      >
        {/* Icon */}
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: isExpanded ? 45 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <FaPlus size={28} color={textbottoncolor} />
        </motion.div>

        {/* Text (visible only when expanded) */}
        {isExpanded && (
          <motion.p
            className="ml-2 font-bold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{ color: textbottoncolor }}
          >
            {translations[lang].addToContacts}
          </motion.p>
        )}

        {/* Spark Effect */}
        {isExpanded && (
          <motion.div
            className="absolute"
            variants={sparkVariants}
            initial="initial"
            animate="animate"
            style={{
              top: "-20px",
              right: "-20px",
              width: "10px",
              height: "10px",
              backgroundColor: buttonColor,
              borderRadius: "50%",
              boxShadow: `0 0 8px ${buttonColor}`,
            }}
          />
        )}

        {/* Add more sparks for effect */}
        {isExpanded && (
          <>
            <motion.div
              className="absolute"
              variants={sparkVariants}
              initial="initial"
              animate="animate"
              style={{
                bottom: "-20px",
                left: "-20px",
                width: "10px",
                height: "10px",
                backgroundColor: buttonColor,
                borderRadius: "50%",
                boxShadow: `0 0 8px ${buttonColor}`,
              }}
            />
            <motion.div
              className="absolute"
              variants={sparkVariants}
              initial="initial"
              animate="animate"
              style={{
                top: "-20px",
                left: "-20px",
                width: "10px",
                height: "10px",
                backgroundColor: buttonColor,
                borderRadius: "50%",
                boxShadow: `0 0 8px ${buttonColor}`,
              }}
            />
          </>
        )}
      </motion.div>
</div>


<Box className="fixed z-50 bottom-4 left-1 w-14 h-14 rounded-full flex justify-center items-center text-[var(--button-text-color)]">
  <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
  >
      <Box
          sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 'full',
              height: 'auto',
             
          }}
      >
          <div
              style={{
                  borderRadius: "20px", // Adjust as needed for roundness
                  overflow: "hidden",
              }}
          >
              <QRCode
value={urlToShare}            // here you should keep the link/value(string) for which you are generation promocode
logoImage={`${form?.profileImage}`}  // URL of the logo you want to use, make sure it is a dynamic url
logoHeight={40}
logoWidth={40}
logoOpacity={1} 
bgColor={form.colors.buttonsBackground}        
fgColor={textbottoncolor}         
size={250}
                   qrStyle="dots" 
            eyeRadius={10} 
          
              />

          </div>
      </Box>
  </Modal>
  <Box ref={speedDialRef} className='fixed bottom-0  left-4 '   style={{
    whiteSpace: "nowrap",
    zIndex: "0",
    color: `black`,
    position:"fixed",
    zIndex:100,
  }}>


     <motion.div
   variants={speedDialAnimation}
   initial="initial"
   animate="animate"
   >

      <SpeedDial
icon={<PiUploadSimpleBold size={'28'}  color={textbottoncolor}/>}
ariaLabel="Teb social Shares"
onClick={handleSpeedDialClick} // Toggle tooltips and SpeedDial state
sx={{
position: 'absolute', 

bottom: 35,
color: `${textbottoncolor}`,
left: 16,
zIndex: 40,
'& .MuiSpeedDial-fab': {
 backgroundColor: `${form.colors.buttonsBackground} !important`,
 '&:hover': {
   backgroundColor: `${form.colors.buttonsBackground} !important`,
 },
       border: `2px solid ${textcolor}`, // Add 2px border to the main SpeedDial button

},
}}

 open={oppen}
 >
 {/* QR Code Action */}
 <SpeedDialAction
 sx={{
   '&:hover': {
     transform: 'scale(1.05)',
   },
   

 }}
FabProps={{ size: "medium", style: { backgroundColor: form.colors.buttonsBackground } }}

icon={<IoQrCodeOutline style={{ color: textbottoncolor }} size={25} />}
tooltipTitle={translations[lang].myQrCode}
tooltipPlacement="right"
tooltipOpen
onClick={handleOpen}

/>

{/* Copy Link Action */}
<SpeedDialAction
sx={{
'&:hover': {
 transform: 'scale(1.05)',
},


}}
FabProps={{ size: "medium", style: { backgroundColor: form.colors.buttonsBackground } }}

icon={<FaRegCopy size={25} style={{ color: textbottoncolor }} />}
tooltipTitle={translations[lang].copyLink}
tooltipPlacement="right"
tooltipOpen       
onClick={handleCopyLink}
/>

{/* Share Action */}
<SpeedDialAction
sx={{
'&:hover': {
 transform: 'scale(1.05)',
},


}}
FabProps={{ size: "medium", style: { backgroundColor: form.colors.buttonsBackground } }}

icon={
 <RWebShare
   data={{
     url: urlToShare,
     title: " Teb Social Profile Link",
   }}
   onClick={() => setOppen(!oppen)}
 >
   <FaShareFromSquare  data={{
     url: urlToShare,
     title: "Share your Teb Social Profile Link",
   }}             onClick={() => setOppen(!oppen)}
style={{ color: textbottoncolor }} size={25} />
 </RWebShare>
}
tooltipTitle={translations[lang].shareLink}
tooltipPlacement="right"
tooltipOpen 
/>
</SpeedDial>
</motion.div>
</Box>


</Box>



       {isVisible && (
         <div className="z-50 fixed bottom-16 left-1/2 transform -translate-x-1/2 border-2 bg-gray-700 text-white rounded-xl px-2">
             link copied
         </div>
     )}

    </div>
    







        




      </div>
    </div>
  );
};

export default Template16;
